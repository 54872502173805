<template>
  <div class="inline-text-edit w-100">
    <div class="inline-text-edit-display ie-edit" v-if="!isEditing">
      <span class="display-text mr-1" @click.stop="Edit">
        <slot name="display">
          <span v-html="text"></span>
        </slot>
      </span>
    </div>
    <div class="d-flex align-items-center" v-else>
      <b-form-input
        @click.stop
        autofocus
        trim
        autocomplete="off"
        v-model="editingValue"
        @keyup.enter="Save"
        class="edit-input my-1 mr-1 w-100"
      ></b-form-input>

      <i
        class="fas fa-save text-black clickable-icon pointer ie-save save-btn mr-2"
        @click.stop="Save"
      ></i>
      <i
        class="fas fa-times text-black clickable-icon pointer ie-cancel cancel-btn"
        @click.stop="Close"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'inline-text-edit',
  data() {
    return {
      isEditing: false,
      editingValue: '',
    };
  },
  mounted() {},
  created() {},
  methods: {
    Edit() {
      this.isEditing = true;
    },
    Save() {
      this.$emit('update:value', this.editingValue);
      this.isEditing = false;
    },
    Close() {
      this.isEditing = false;
    },
  },
  computed: {},
  watch: {
    isEditing: {
      handler: function(v) {
        if (v) {
          this.editingValue = this.value;
        }
      },
      immediate: true,
    },
    value: {
      handler: function(v) {
        this.editingValue = v;
      },
      immediate: true,
    },
  },
  props: {
    value: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.display-text {
  border-bottom: 1px solid #343a4080;
  line-height: 1em;
}
.inline-text-edit-display {
  min-height: 35px;
  align-items: center;
  display: flex;
}
.edit-input {
  height: 27px;
}
.save-btn {
  margin-left: 3px;
  margin-right: 3px;
}
</style>
